import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import RoomPagePreview from "../components/RoomPreview/RoomPagePreview";
import config from "../../content/meta/config";

const RoomsPage = props => {
  const {
    data: {
      roomImage1: {
        resize: { src: roomImage1 }
      },
      roomImage2: {
        resize: { src: roomImage2 }
      },
      roomImage3: {
        resize: { src: roomImage3 }
      }
    }
  } = props;

  const roomImages = [roomImage1, roomImage2, roomImage3];

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <div className="rooms-container">
            {config.rooms.map((room, index) => (
              <RoomPagePreview
                key={room.title}
                theme={theme}
                images={[roomImages[index]]}
                room={room}
              />
            ))}
          </div>
        )}
      </ThemeContext.Consumer>

      <style jsx>{`
        .rooms-container {
          margin-top: 100px;
        }

        @below desktop {
          .rooms-container {
            margin-top: 85px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

RoomsPage.propTypes = {
  data: PropTypes.object
};

export default RoomsPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query RoomsQuery {
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    roomImage1: imageSharp(fluid: { originalName: { regex: "/room1/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    roomImage2: imageSharp(fluid: { originalName: { regex: "/room2/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    roomImage3: imageSharp(fluid: { originalName: { regex: "/room3/" } }) {
      resize(width: 2200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
  }
`;
