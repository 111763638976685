import React from "react";
import * as R from "ramda";
import PropTypes from "prop-types";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

import RoomPreviewContent from "./RoomPreviewContent";

const RoomPagePreview = props => {
  const { theme, images, room } = props;

  const rental = R.pipe(
    R.path(["description"]),
    R.find(R.propEq("key", "rental")),
    R.path(["value", "th"])
  )(room);

  if (typeof window === "undefined") {
    return <p>Server Render</p>;
  }

  return (
    <section className="container">
      <div className="header">{room.title}</div>
      <div className="container-wrapper">
        <div className="wrapper">
          <div className="slideshow">
            <Splide
              options={{
                type: "fade",
                rewind: true,
                width: "100%",
                cover: true,
                height: "100%",
                autoplay: false
              }}
            >
              {images.map((image, index) => (
                <SplideSlide key={image}>
                  <img src={image} alt={`Image ${index}`} />
                </SplideSlide>
              ))}
            </Splide>
          </div>
          <div className="content">
            <div className="rental">{rental}</div>
            <RoomPreviewContent room={room} theme={theme} />
          </div>
        </div>
      </div>

      {/* --- STYLES --- */}
      <style jsx>{`
        .container {
          padding-top: 40px;
          padding-bottom: 40px;

          &:nth-child(2n) {
            .wrapper {
              flex-direction: row-reverse;
            }

            .header {
              text-align: right;
            }
          }
        }

        .rental {
          width: 300px;
          max-width: 70vw;
          padding: 10px 20px;
          margin: 10px -50px 20px auto;
          text-align: center;
          font-family: ${theme.font.family.thaiText};
          color: ${theme.color.neutral.white};
          background: ${theme.color.neutral.gray.k};
        }

        .container:nth-child(2n) .rental {
          margin: 10px auto 20px -50px;
        }

        .header {
          font-size: 48px;
          font-family: ${theme.font.family.simpleHeader};
          text-transform: uppercase;
          color: ${theme.color.brand.primaryBackground};
        }

        .container-wrapper {
          margin-top: -10px;
          padding: 0 2px;
        }

        .wrapper {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: space-between;

          .slideshow {
            width: 55%;
            max-width: 800px;
            height: auto;
            background: #ffffff;
            border-right: 1px solid #ffffff;
            border-left: 1px solid #ffffff;
          }

          .content {
            flex: 1;
            padding: 20px 40px;
            border-right: 1px solid #ffffff;
            border-left: 1px solid #ffffff;
            background: ${theme.color.brand.primaryBackground};

            .title {
              font-family: ${theme.font.family.simpleHeader};
              font-size: ${theme.font.size.xxl};
              color: ${theme.color.neutral.white};
              font-weight: normal;
              text-transform: uppercase;
              padding: 20px 0;
            }

            .button-row {
              margin: 2em 0;

              :global(a) {
                font-size: 15px;
              }
            }
          }
        }

        @from-width desktop {
          .slideshow {
            :global(.splide),
            :global(.splide__track),
            :global(.splide__list),
            :global(.splide__slide) {
              height: 100% !important;
            }
          }
        }

        @below desktop {
          .container {
            padding-top: 20px;
            padding-bottom: 20px;
          }

          .container .rental {
            margin: 10px -30px 20px auto !important;
          }

          .header {
            font-size: 32px;
          }

          .container:nth-child(2n) .header {
            text-align: left;
          }

          .container-wrapper {
            margin-top: -6px;
          }

          .wrapper {
            display: block;

            .slideshow {
              width: 100%;
              max-width: unset;
            }

            .content {
              width: 100%;
              padding: 10px 20px;
              border-top: 2px solid #ffffff;

              .button-row {
                margin: 1em 0;

                :global(a) {
                  font-size: 14px;
                }
              }
            }
          }
        }
      `}</style>
    </section>
  );
};

RoomPagePreview.propTypes = {
  theme: PropTypes.object.isRequired,
  images: PropTypes.array,
  room: PropTypes.object.isRequired
};

export default RoomPagePreview;
